import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import EventsUpcoming from "../components/events-upcoming"
import EventsPast from "../components/events-past"

import style from "./speaking.module.css"

const EventsTemplate = ({ data }) => {
  return (
    <Layout>
      <section className={style.speaking}>
        <h2 className={style.section__heading}>Speaking</h2>
        <div className={style.speaking__container}>
          <figure className={style.speaking__image}>
            <Img
              fluid={data.speakingImage.childImageSharp.fluid}
              alt="Morten Rand-Hendriksen"
            />
          </figure>
          <div className={style.speaking__blurb}>
            <p>
              One of my many titles is &ldquo;Speaker at Tech Events.&rdquo;
              Over the years my talkhs have covered a wide range of topics; from
              web standards to open source contribution to advanced tools and
              techniques to human-centered design.
            </p>
            <p>
              These days the primary focus of my talks is around the human
              element in tech: How to design and build things for the web that
              move us forward together. Current topics range from tech and
              design ethics to how to stay on the cutting edge of an ever-moving
              industry.
            </p>
          </div>
        </div>
        <div className={style.events}>
          <EventsUpcoming />
          <EventsPast />
        </div>
      </section>
    </Layout>
  )
}

export default EventsTemplate

export const query = graphql`
  query {
    speakingImage: file(relativePath: { eq: "morten_RRS.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
